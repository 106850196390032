/** Shelf Life: Bird animation **/

import React from "react";
import { gsap, Quad } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages, scaleFullscreen } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData;
let cloudsInitiated = false;

class ShelfLifePage1 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[1];
    preloadData = globalState.assets[2];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);


    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add base track
    //globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    //set volume for base track
    globalState.baseAudioRef.updateVolume(2, 60);
    globalState.baseAudioRef.updateTrack(1, false);
    
    //Update dimensions
    this.updateDimensions();

    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.caption,
      globalState.cdnUrl + preloadData.bgVideoPoster,
      globalState.cdnUrl + preloadData.slide1,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide6,
      globalState.cdnUrl + preloadData.slide7,
      globalState.cdnUrl + preloadData.slide8,
      globalState.cdnUrl + preloadData.slide9,
      globalState.cdnUrl + preloadData.slide10,
      globalState.cdnUrl + preloadData.slide11,
      globalState.cdnUrl + preloadData.slide12,
      globalState.cdnUrl + preloadData.slide13,
      globalState.cdnUrl + preloadData.slide14,
      globalState.cdnUrl + preloadData.slide15
    ], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    gsap.killTweensOf(this.refs.cloudWrapperRef);
    window.removeEventListener("resize", this.updateDimensions);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
      this.bgVideoRef.seekVideo(30);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop, 0);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }
  
  updateDimensions = () => {
    console.log('updateDimensions');
    //background native size is 1288 x 724
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1288, 724, true);
  }
  
  animateClouds = (num) =>{
    console.log(num)
    if(num > 0 && !cloudsInitiated){
      cloudsInitiated = true;
      gsap.to(this.refs.cloudWrapperRef, 7, {x: "1280px", ease:Quad.easeOut, onComplete:this.bounceClouds});
    }
  }

  bounceClouds = () => {
    gsap.to(this.refs.cloudWrapperRef, 3, {y: "10px", ease:Quad.easeInOut, repeat: -1, yoyo: true});
  }

  render() {

    return (

      <>

        <link rel="prefetch" href="/shelf-life/2" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/shelf-life/2" prevLocation="/shelf-life"
          isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Shelf Life" />

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "2vw", marginTop: "5vh" }} onClick={this.toggleTextDisplay} alt="IN GOOD TIMES, WE’RE USUALLY PRETTY OPTIMISTIC. THERE WILL BE GROWTH, WORK, MARKETS WILL RISE. When there’s a downturn, and headlines turn ominous, we might get a sense that a way of life is ending for someone, somewhere. I’ve never lost a job, or even been laid off. I’m pretty sure that every place I’ve ever worked is still open. (Except the North Keg in Regina – definitely closed.) Who can relate to an entire town closing except people whose town has closed?" />
            </div>
          </div>

          
          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={false} muted={false} ended={this.bgVideoEnded} cuePoints={[17]} cuePointCallback={this.animateClouds} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }

          <div className="fullscreen-bg" ref="fullscreenBgRef">
            <div className="cloud-wrapper" ref="cloudWrapperRef">
              <img src={globalState.cdnUrl + data.cloud} className="cloud cloud1" alt="" />
              <img src={globalState.cdnUrl + data.cloud} className="cloud cloud2" alt="" />
            </div>
          </div>

        </div>
        )}
      </>
    );
  }


};

export default ShelfLifePage1
